import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Combos = () => (
  <>
    <SEO title="Combos" />

    <Menu className="menu">
      <Slide left cascade duration={300}>
        <h1 className="menu-title">Combos</h1>

        <h2 className="menu-info">
          Sabores salgadas: Frango c/ Catupiry, Brócolis, Baiana, Calabresa,
          Milho e Tradicional. <br />
          <br />
          Doces: Brigadeiro, Prestígio, Chocobana e Banana Nevada.
          <br />
          <br />
          ** Promoção válida para Segunda e Quinta-feira. **
        </h2>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Combo 1</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">70,00</h4>
          </div>
        </div>

        <p className="item-desc">
          8 pedaços + 4 pedaços + 1 Refri. Promocional
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Combo 2</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">80,00</h4>
          </div>
        </div>

        <p className="item-desc">
          8 pedaços + 8 pedaços + 1 Refri. Promocional
        </p>
      </Item>
    </Menu>
  </>
);

export default Combos;
